import React, { Fragment,} from 'react'
import styled from 'styled-components'
import UpsellExtensionPreview from './upsellExtensionPreview'
import UpsellPreview from './upsellPreview'
import Divider from '../../../components/divider'
import {
    Icon,
  } from "@shopify/polaris"
import { CartIcon, ChevronUpIcon, ChevronRightIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import formatCurrency from '../../../helpers/formatCurrency'
  const style = {
    giftCardInput: {
      "appearance": "none",
      "backgroundClip": "padding-box",
      "backgroundColor": "#fff",
      "border": "1px solid #d5d5d5",
      "borderRadius": "5px",
      "boxSizing": "border-box",
      "color": "#333",
      "display": "inline-block",
      "fontFamily": "-apple-system,system-ui,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",sans-serif",
      "fontSize": "14px",
      "fontWeight": "400",
      "lineHeight": "18.2px",
      "margin": "0",
      "padding": ".928571em .785714em",
      "transition": "all .2s ease-out",
      "wordBreak": "normal",
      "width": '100%',
    },
    giftCardApplyButton: {
      "cursor": "default",
      "background": "#c8c8c8",
      "boxShadow": "none",
      "boxSizing": "border-box",
      "border": "1px transparent solid",
      "borderRadius": "5px",
      "display": "inline-block",
      "width": "auto",
      "marginLeft": "0.8571428571em",
      "whiteSpace": "nowrap",
      "padding": "1em 1.7em",
      "flexShrink": 1,
    },
    giftCardApplyButtonContent: {    
      "position": "relative",
      "transition": "opacity 0.3s ease-in-out",
      "color": "white",
      "fontWeight": "500",
      "textAlign": "center",
      "textTransform": "none",
    },
    flex: {
      display: 'flex',
    },
    leftColumn: {
      width: '100%',
    },
    leftColumnContent: {
      "fontWeight": "400",
      "padding": 0,
      "fontFamily": "-apple-system, system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif'\nfont-size: 14px",
      "fontWeight": "400",
      "color": "rgb(83, 83, 83)",
      "lineHeight": "18.2px",
      "textAlign": "left",
    },
    rightColumn: {
      flexShrink: 1,
    },
    rightColumnNextStep: {
      "textAlign": "right",
      "whiteSpace": "nowrap",
    },
    rightColumnContent: {
      "color": "rgb(50, 50, 50)",
      "display": "inline",
      "fontFamily": "-apple-system, system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif",
      "fontSize": "14px",
      "fontWeight": "500",
    },
    nextStep: {
      "color": "#717171",
      "fontSize": "12px",
    },
  }
const MobilePreviewWrapper = styled.div`
    .order-summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.7em 0.7em;
        .order-summary-title {
            display: flex;
            gap: 15px;
        }
        .order-summary-price {
            font-size: 1.2857142857em;
            line-height: 1em;
            color: #333333;
            font-weight: 700;
        }
    }
    .cart-product-wrapper {
        margin: 35px 0 20px 0;
        .cart-product-container {
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        .cart-product-image-container {
            box-sizing: border-box;
            display: inline-block;
            width: 64px;
            height: 64px;
            border-radius: 5px;
            border: 1px solid #aaa;
            margin-right: 6px;
            background: white;
            align-self: center;
            .cart-product-image {
            object-fit: contain;
            object-position: center;
            height: 62px;
            width: 62px;
            }
        }
        .cart-product-title-container {
            box-sizing: border-box;
            display: flex;
            flex: 2;
            flex-direction: column;
            margin: auto;
            min-width: 100px;
            padding: 5px;
            text-align: left;
            padding-right: 12px;
            max-width: 218px;
            .cart-product-title {
            box-sizing: border-box;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 2px;
            line-height: 18px;
            text-align: left;
            }
            .cart-product-subtitle {
            box-sizing: border-box;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            }
        }
        .cart-product-action-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px 0;
            .cart-product-price {
            box-sizing: border-box;
            color: #212b36;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            text-align: right;
            }
        }
        
        }
    }
    .breadcrumb {
        background: #fff;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        gap: 5px;
        span {
            font-size: 0.8571428571em;
            color: #737373;
            margin: 0;
        }
    }
    .payment {
        background: #fff;
        padding: 5px;
        .payment-title {
            color: #333333;
            font-size: 1em;
            font-weight: 700;
            margin: 0;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: end;
            -webkit-align-items: flex-end;
            -ms-flex-align: end;
            align-items: flex-end;
            text-align: center;
        }
        .payment-title::before {
            border-right: 0;
            border-top-left-radius: 5px;
            margin-right: 1em;
        }
        .payment-title::before, .payment-title::after {
            content: '';
            border: 1px #e6e6e6 solid;
            border-bottom: 0;
            height: 0.5em;
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 2em;
            -ms-flex: 1 0 2em;
            flex: 1 0 2em;
        }
        .payment-title::after {
            border-left: 0;
            border-top-right-radius: 5px;
            margin-left: 1em;
        }
        .payment-content {
            border: 1px #e6e6e6 solid;
            border-top: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0.9285714286em 1.4285714286em 1.4285714286em;
            display: flex;
            gap: 7px;
            flex-direction: column;
            .payment-method-item {
                height: 45px;
                background: #E7E7E7;
                border-radius: 4px;
            }
        }
    }
    .alternative-payment-separator {
        background: #fff;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 0.8571428571em;
        line-height: 1.4;
        text-align: center;
        text-transform: uppercase;
        color: #737373;
        padding-top: 2.5em;
        
        .alternative-payment-separator-content {

        }
    }
    .alternative-payment-separator::before, .alternative-payment-separator::after {
            content: '';
            display: inline-block;
            height: 1px;
            background-color: #e6e6e6;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
    }
    .contact-information {
        background: #fff;
        padding: 5px;
        margin-bottom: 50px;
        h2 {
            padding-bottom: 10px;
        }
        input {
            width: 100%;
            border: 1px #A7ACB1 solid;
            background-clip: padding-box;
            border-radius: 5px;
            padding: 0.9285714286em 0.7857142857em;
        }
    }
    .shipping-information {
      padding: 5px;
      margin-bottom: 50px;
      h2 {
        padding-bottom: 10px;
      }
      input {
          width: 100%;
          border: 1px #A7ACB1 solid;
          background-clip: padding-box;
          border-radius: 5px;
          padding: 0.9285714286em 0.7857142857em;
          margin-bottom: 10px;
      }
      .name {
        display: flex;
        flex-direction: row;
        gap: 10px;
        input {
          width: 50%;
        }
      }
      .address {
        display: flex;
        flex-direction: row;
        gap: 10px;
        input {
          width: 33.333%;
        }
      }
  }
  `
function MobilePreview(props) {
  const locale = props.shopData && props.shopData.shopData && props.shopData.shopData.primary_locale || "en-US";
  const currency = props.shopData && props.shopData.shopData && props.shopData.shopData.currency || "USD";
    return (
        props.shopData && ("PAEP" === props.shopData.checkoutOfferPosition || "OPMD" === props.shopData.checkoutOfferPosition) ?
        <Fragment>
             <MobilePreviewWrapper>
                {/*
                <div className='order-summary' style={{background:"#fff", borderTop:'1px solid #DBDDDF', borderBottom:'1px solid #DBDDDF', marginTop:'30px'}}>
                    <div className='order-summary-title'>
                        <Icon
                        source={CartIcon}
                        tone="base" />
                        <span>Show order summary</span>
                        <Icon
                        source={ChevronDownIcon}
                        tone="base" />
                    </div>
                    <div className='order-summary-price'>
                        {formatCurrency(125.00, locale, currency)}
                    </div>
                </div>
                
                <div className='breadcrumb' style={{background:"transparent"}}>
                    <span>Cart</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span style={{color: '#333333'}}>Information</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span>Shipping</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span>Payment</span>
                </div>
                */}
                {"extension" === props.checkoutType ?
                <UpsellExtensionPreview {...props} key={props.variants ? props.variants[0].id : null} type="Mobile"/>
                :
                <UpsellPreview {...props} key={props.variants ? props.variants[0].id : null} type="Mobile"/>
                }
                {/*
                <div className='payment' style={{background:"transparent"}}>
                    <h2 className='payment-title'>Express checkout</h2>
                    <div className='payment-content'>
                        <div className='payment-method-item'>

                        </div>
                        <div className='payment-method-item'>

                        </div>
                    </div>
                </div>
                <div className='alternative-payment-separator' style={{background:"transparent"}}>
                    <span className='alternative-payment-separator-content'>OR</span>
                </div>
                <div className='contact-information' style={{background:"transparent"}}>
                    <h2>Contact information</h2>
                    <input placeholder="Email or mobile phone number"></input>
                </div>
                <div className='shipping-information'>
                    <h2>Shipping address</h2>
                    <div className='name'>
                      <input placeholder="First name"></input>
                      <input placeholder="Last name"></input>
                    </div>
                    <input placeholder="Address"></input>
                    <input placeholder="Apartment, suite, etc. (optional)"></input>
                    <input placeholder="City"></input>
                    <div className='address'>
                      <input placeholder="Country/region"></input>
                      <input placeholder="State"></input>
                      <input placeholder="ZIP code"></input>
                    </div>
                    <input placeholder="Phone (optional)"></input>
                </div>
                */}
            </MobilePreviewWrapper>
        </Fragment>
        :
        <Fragment>
             <MobilePreviewWrapper>
                {/* 
                <div style={{marginTop:'30px'}}>
                    <Divider/>
                </div>
                <div className='order-summary'>
                    <div className='order-summary-title'>
                        <Icon
                        source={CartIcon}
                        tone="base" />
                        <span>Hide order summary</span>
                        <Icon
                        source={ChevronUpIcon}
                        tone="base" />
                    </div>
                    <div className='order-summary-price'>
                        {formatCurrency(125.00, locale, currency)}
                    </div>
                </div>
                <Divider />
                */}
                <div className='cart-product-wrapper'>
                    <div className="cart-product-container">
                    <div className="cart-product-image-container">
                        <img className="cart-product-image" src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-product.png?alt=media&token=98f045eb-038f-45de-9d52-9b9b6d1db2b1" alt="" />
                    </div>
                    <div className="cart-product-title-container">
                        <p className="cart-product-title" >
                            The Classic Cobbler
                        </p>
                        <p className="cart-product-subtitle" >
                            Retro Rainbow
                        </p>
                    </div>
                    <div className="cart-product-action-container">
                        <div>
                            <span className="cart-product-price">{formatCurrency(125.00, locale, currency)}</span>
                        </div>
                    </div>
                    </div>
                </div>
                <Divider />
                <div style={{
                    ...style.flex,
                    margin: '32px 0',
                }}>
                    <input
                        type="text" 
                        placeholder="Gift card or discount code" 
                        onChange={() => {}}
                        disabled
                        style={style.giftCardInput}
                    />
                    <button 
                        disabled
                        style={style.giftCardApplyButton}
                    >
                        <span style={style.giftCardApplyButtonContent}>
                        Apply
                        </span>
                    </button>
                </div>
                {"extension" === props.checkoutType ?
                <UpsellExtensionPreview {...props} key={props.variants ? props.variants[0].id : null} type="Mobile"/>
                :
                <UpsellPreview {...props} key={props.variants ? props.variants[0].id : null} type="Mobile"/>
                }
                <Divider />
                <div style={{ ...style.flex, marginTop: 10 }}>
                    <div style={style.leftColumn}>
                    <span style={style.leftColumnContent}>Subtotal</span>
                    </div>
                    <div style={style.rightColumn}>
                    <span style={style.rightColumnContent}>{formatCurrency(24.99, locale, currency)}</span>
                    </div>
                </div>
                <div style={{ ...style.flex, marginTop: 8, marginBottom: 16 }}>
                    <div style={style.leftColumn}>
                    <span style={style.leftColumnContent}>Shipping</span>
                    </div>
                    <div style={{ ...style.rightColumn, ...style.rightColumnNextStep }}>
                    <span style={{ ...style.rightColumnContent, ...style.nextStep}}>Calculated at next step</span>
                    </div>
                </div>
                <Divider />
                <div style={{ ...style.flex, marginTop: 16, marginBottom: 30 }}>
                    <div style={style.leftColumn}>
                    <span style={{ 
                        ...style.leftColumnContent,
                        "color": "#323232",
                        "fontSize": "16px"
                    }}>Total</span>
                    </div>
                    <div style={{ flexShrink: 1 }}>
                    <div style={{
                        paddingTop: 3,
                        "color": "#717171",
                        "fontSize": "12px",
                        "marginRight": ".5em",
                        "verticalAlign": ".2em"
                    }}>USD</div>
                    </div>
                    <div style={{ flexShrink: 1 }}>
                    <span style={{
                        "color": "#323232",
                        "fontSize": "24px",
                        "fontWeight": "500",
                        "letterSpacing": "-.04em",
                        "lineHeight": "1em"
                    }}>{formatCurrency(24.99, locale, currency)}</span>
                    </div>
                </div>
                {/* 
                <Divider />
                <div className='breadcrumb'>
                    <span>Cart</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span style={{color: '#333333'}}>Information</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span>Shipping</span>
                    <Icon
                        source={ChevronRightIcon}
                        tone="base" />
                    <span>Payment</span>
                </div>
                <div className='payment'>
                    <h2 className='payment-title'>Express checkout</h2>
                    <div className='payment-content'>
                        <div className='payment-method-item'>

                        </div>
                        <div className='payment-method-item'>

                        </div>
                    </div>
                </div>
                <div className='alternative-payment-separator'>
                    <span className='alternative-payment-separator-content'>OR</span>
                </div>
                <div className='contact-information'>
                    <h2>Contact information</h2>
                    <input placeholder="Email or mobile phone number"></input>
                </div>
                */}
            </MobilePreviewWrapper>
        </Fragment>
    )
}
export default MobilePreview;